// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Courier New', Courier, monospace;
    color: #333;
  }
  
  .faq-container h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #555;
    font-family: inherit; /* Inherit the font from faq-container */
  }
  
  .faq-section {
    margin-bottom: 20px;
  }
  
  .faq-section h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
    font-family: inherit; /* Inherit the font from faq-container */
  }
  
  .faq-section p {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
    font-family: inherit; /* Inherit the font from faq-container */
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/FAQ.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,8CAA8C;IAC9C,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,oBAAoB,EAAE,wCAAwC;EAChE;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,oBAAoB,EAAE,wCAAwC;EAChE;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,oBAAoB,EAAE,wCAAwC;EAChE","sourcesContent":[".faq-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    font-family: 'Courier New', Courier, monospace;\n    color: #333;\n  }\n  \n  .faq-container h1 {\n    text-align: center;\n    margin-bottom: 30px;\n    color: #555;\n    font-family: inherit; /* Inherit the font from faq-container */\n  }\n  \n  .faq-section {\n    margin-bottom: 20px;\n  }\n  \n  .faq-section h2 {\n    font-size: 1.5rem;\n    color: #333;\n    margin-bottom: 10px;\n    font-family: inherit; /* Inherit the font from faq-container */\n  }\n  \n  .faq-section p {\n    font-size: 1rem;\n    line-height: 1.6;\n    color: #666;\n    font-family: inherit; /* Inherit the font from faq-container */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
