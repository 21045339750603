// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Filters.css */

.filters-container {
    padding-top: 30px;
    padding-bottom: 1px;
    text-align: center;
    margin: 20px 0;
  }
  
  .filters {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .filter-brace {
    font-size: 46px;
    font-weight: bold;
    margin: 0 30px; /* Ensure equal spacing around the braces */
  }
  
  .filter-item {
    filter: opacity(50%);
    transition: ease-in-out 0.3s;
    display: inline-block;
    margin: 0 10px;
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .filter-item:hover {
    filter: none;
    color: #000;
  }
  
  .filters-line {
    width: 60px;
    height: 2px;
    background-color: #000;
    margin: 45px auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Filters.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;IACI,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,cAAc,EAAE,2CAA2C;EAC7D;;EAEA;IACE,oBAAoB;IACpB,4BAA4B;IAC5B,qBAAqB;IACrB,cAAc;IACd,eAAe;IACf,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,iBAAiB;EACnB","sourcesContent":["/* src/styles/Filters.css */\n\n.filters-container {\n    padding-top: 30px;\n    padding-bottom: 1px;\n    text-align: center;\n    margin: 20px 0;\n  }\n  \n  .filters {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .filter-brace {\n    font-size: 46px;\n    font-weight: bold;\n    margin: 0 30px; /* Ensure equal spacing around the braces */\n  }\n  \n  .filter-item {\n    filter: opacity(50%);\n    transition: ease-in-out 0.3s;\n    display: inline-block;\n    margin: 0 10px;\n    font-size: 18px;\n    cursor: pointer;\n    font-weight: bold;\n  }\n  \n  .filter-item:hover {\n    filter: none;\n    color: #000;\n  }\n  \n  .filters-line {\n    width: 60px;\n    height: 2px;\n    background-color: #000;\n    margin: 45px auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
