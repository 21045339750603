import CupcakePhoto1 from "./photos/CupcakePhoto1.jpg";
import CupcakePhoto2 from "./photos/CupcakePhoto2.jpg";
import CupcakePhoto3 from "./photos/CupcakePhoto3.jpg";
import CupcakePhoto4 from "./photos/CupcakePhoto4.jpg";
import CupcakePhoto5 from "./photos/CupcakePhoto5.jpg";
import CupcakePhoto6 from "./photos/CupcakePhoto6.jpg";
import CupcakePhoto7 from "./photos/CupcakePhoto7.jpg";
import CupcakePhoto8 from "./photos/CupcakePhoto8.jpg";
import CupcakePhoto9 from "./photos/CupcakePhoto9.jpg";
import CupcakePhoto10 from "./photos/CupcakePhoto10.jpg";
import CupcakePhoto11 from "./photos/CupcakePhoto11.jpg";
import CupcakePhoto12 from "./photos/CupcakePhoto12.jpg";
import CupcakePhoto13 from "./photos/CupcakePhoto13.jpg";
import CupcakePhoto14 from "./photos/CupcakePhoto14.jpg";
import CupcakePhoto15 from "./photos/CupcakePhoto15.jpg";
import CupcakePhoto16 from "./photos/CupcakePhoto16.jpg";
import CupcakePhoto17 from "./photos/CupcakePhoto17.jpg";
import CupcakePhoto18 from "./photos/CupcakePhoto18.jpg";
import CupcakePhoto19 from "./photos/CupcakePhoto19.jpg";
import CupcakePhoto20 from "./photos/CupcakePhoto20.jpg";
import CupcakePhoto21 from "./photos/CupcakePhoto21.jpg";
import CupcakePhoto22 from "./photos/CupcakePhoto22.jpg";
import CupcakePhoto23 from "./photos/CupcakePhoto23.jpg";
import CupcakePhoto24 from "./photos/CupcakePhoto24.jpg";
import CupcakePhoto25 from "./photos/CupcakePhoto25.jpg";
import CupcakePhoto26 from "./photos/CupcakePhoto26.jpg";
import CupcakePhoto27 from "./photos/CupcakePhoto27.jpg";
import CupcakePhoto28 from "./photos/CupcakePhoto28.jpg";
import CupcakePhoto29 from "./photos/CupcakePhoto29.jpg";
import CupcakePhoto30 from "./photos/CupcakePhoto30.jpg";
import CupcakePhoto31 from "./photos/CupcakePhoto31.jpg";
import CupcakePhoto32 from "./photos/CupcakePhoto32.jpg";
import CupcakePhoto33 from "./photos/CupcakePhoto33.jpg";
import CupcakePhoto34 from "./photos/CupcakePhoto34.jpg";
import CupcakePhoto35 from "./photos/CupcakePhoto35.jpg";
import CupcakePhoto36 from "./photos/CupcakePhoto36.jpg";

import CakePhoto1 from "./photos/CakePhoto1.jpg";
import CakePhoto2 from "./photos/CakePhoto2.jpg";
import CakePhoto3 from "./photos/CakePhoto3.jpg";
import CakePhoto4 from "./photos/CakePhoto4.jpg";
import CakePhoto5 from "./photos/CakePhoto5.jpg";
import CakePhoto6 from "./photos/CakePhoto6.jpg";
import CakePhoto7 from "./photos/CakePhoto7.jpg";
import CakePhoto8 from "./photos/CakePhoto8.jpg";
import CakePhoto9 from "./photos/CakePhoto9.jpg";
import CakePhoto10 from "./photos/CakePhoto10.jpg";
import CakePhoto11 from "./photos/CakePhoto11.jpg";
import CakePhoto12 from "./photos/CakePhoto12.jpg";
import CakePhoto13 from "./photos/CakePhoto13.jpg";
import CakePhoto14 from "./photos/CakePhoto14.jpg";
import CakePhoto15 from "./photos/CakePhoto15.jpg";
import CakePhoto16 from "./photos/CakePhoto16.jpg";
import CakePhoto17 from "./photos/CakePhoto17.jpg";
import CakePhoto18 from "./photos/CakePhoto18.jpg";
import CakePhoto19 from "./photos/CakePhoto19.jpg";
import CakePhoto20 from "./photos/CakePhoto20.jpg";
import CakePhoto21 from "./photos/CakePhoto21.jpg";
import CakePhoto22 from "./photos/CakePhoto22.jpg";
import CakePhoto23 from "./photos/CakePhoto23.jpg";
import CakePhoto24 from "./photos/CakePhoto24.jpg";
import CakePhoto25 from "./photos/CakePhoto25.jpg";
import CakePhoto26 from "./photos/CakePhoto26.jpg";
import CakePhoto27 from "./photos/CakePhoto27.jpg";
import CakePhoto28 from "./photos/CakePhoto28.jpg";
import CakePhoto29 from "./photos/CakePhoto29.jpg";
import CakePhoto30 from "./photos/CakePhoto30.jpg";
import CakePhoto31 from "./photos/CakePhoto31.jpg";
import CakePhoto32 from "./photos/CakePhoto32.jpg";
import CakePhoto33 from "./photos/CakePhoto33.jpg";
import CakePhoto34 from "./photos/CakePhoto34.jpg";
import CakePhoto35 from "./photos/CakePhoto35.jpg";
import CakePhoto36 from "./photos/CakePhoto36.jpg";
import CakePhoto37 from "./photos/CakePhoto37.jpg";
import CakePhoto38 from "./photos/CakePhoto38.jpg";
import CakePhoto39 from "./photos/CakePhoto39.jpg";
import CakePhoto40 from "./photos/CakePhoto40.jpg";
import CakePhoto41 from "./photos/CakePhoto41.jpg";
import CakePhoto42 from "./photos/CakePhoto42.jpg";
import CakePhoto43 from "./photos/CakePhoto43.jpg";
import CakePhoto44 from "./photos/CakePhoto44.jpg";
import CakePhoto45 from "./photos/CakePhoto45.jpg";
import CakePhoto46 from "./photos/CakePhoto46.jpg";
import CakePhoto47 from "./photos/CakePhoto47.jpg";
import CakePhoto48 from "./photos/CakePhoto48.jpg";
import CakePhoto49 from "./photos/CakePhoto49.jpg";
import CakePhoto50 from "./photos/CakePhoto50.jpg";
import CakePhoto51 from "./photos/CakePhoto51.jpg";
import CakePhoto52 from "./photos/CakePhoto52.jpg";
import CakePhoto53 from "./photos/CakePhoto53.jpg";
import CakePhoto54 from "./photos/CakePhoto54.jpg";
import CakePhoto55 from "./photos/CakePhoto55.jpg";
import CakePhoto56 from "./photos/CakePhoto56.jpg";
import CakePhoto57 from "./photos/CakePhoto57.jpg";
import CakePhoto58 from "./photos/CakePhoto58.jpg";
import CakePhoto59 from "./photos/CakePhoto59.jpg";
import CakePhoto60 from "./photos/CakePhoto60.jpg";
import CakePhoto61 from "./photos/CakePhoto61.jpg";
import CakePhoto62 from "./photos/CakePhoto62.jpg";
import CakePhoto63 from "./photos/CakePhoto63.jpg";
import CakePhoto64 from "./photos/CakePhoto64.jpg";
import CakePhoto65 from "./photos/CakePhoto65.jpg";
import CakePhoto66 from "./photos/CakePhoto66.jpg";
import CakePhoto67 from "./photos/CakePhoto67.jpg";
import CakePhoto68 from "./photos/CakePhoto68.jpg";
import CakePhoto69 from "./photos/CakePhoto69.jpg";
import CakePhoto70 from "./photos/CakePhoto70.jpg";
import CakePhoto71 from "./photos/CakePhoto71.jpg";
import CakePhoto72 from "./photos/CakePhoto72.jpg";
import CakePhoto73 from "./photos/CakePhoto73.jpg";
import CakePhoto74 from "./photos/CakePhoto74.jpg";
import CakePhoto75 from "./photos/CakePhoto75.jpg";
import CakePhoto76 from "./photos/CakePhoto76.jpg";
import CakePhoto77 from "./photos/CakePhoto77.jpg";
import CakePhoto78 from "./photos/CakePhoto78.jpg";
import CakePhoto79 from "./photos/CakePhoto79.jpg";
import CakePhoto80 from "./photos/CakePhoto80.jpg";
import CakePhoto81 from "./photos/CakePhoto81.jpg";
import CakePhoto82 from "./photos/CakePhoto82.jpg";
import CakePhoto83 from "./photos/CakePhoto83.jpg";
import CakePhoto84 from "./photos/CakePhoto84.jpg";
import CakePhoto85 from "./photos/CakePhoto85.jpg";
import CakePhoto86 from "./photos/CakePhoto86.jpg";
import CakePhoto87 from "./photos/CakePhoto87.jpg";
import CakePhoto88 from "./photos/CakePhoto88.jpg";
import CakePhoto89 from "./photos/CakePhoto89.jpg";
import CakePhoto90 from "./photos/CakePhoto90.jpg";
import CakePhoto91 from "./photos/CakePhoto91.jpg";
import CakePhoto92 from "./photos/CakePhoto92.jpg";
import CakePhoto93 from "./photos/CakePhoto93.jpg";
import CakePhoto94 from "./photos/CakePhoto94.jpg";
import CakePhoto95 from "./photos/CakePhoto95.jpg";
import CakePhoto96 from "./photos/CakePhoto96.jpg";
import CakePhoto97 from "./photos/CakePhoto97.jpg";
import CakePhoto98 from "./photos/CakePhoto98.jpg";
import CakePhoto99 from "./photos/CakePhoto99.jpg";
import CakePhoto100 from "./photos/CakePhoto100.jpg";

const galleryPhotosData = [
  {
    src: CupcakePhoto1,
    alt: "Cupcake 1",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto2,
    alt: "Cupcake 2",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto3,
    alt: "Cupcake 3",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto4,
    alt: "Cupcake 4",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto5,
    alt: "Cupcake 5",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto6,
    alt: "Cupcake 6",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto7,
    alt: "Cupcake 7",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto8,
    alt: "Cupcake 8",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto9,
    alt: "Cupcake 9",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto10,
    alt: "Cupcake 10",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto11,
    alt: "Cupcake 11",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto12,
    alt: "Cupcake 12",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto13,
    alt: "Cupcake 13",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto14,
    alt: "Cupcake 14",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto15,
    alt: "Cupcake 15",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto16,
    alt: "Cupcake 16",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto17,
    alt: "Cupcake 17",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto18,
    alt: "Cupcake 18",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto19,
    alt: "Cupcake 19",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto20,
    alt: "Cupcake 20",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto21,
    alt: "Cupcake 21",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto22,
    alt: "Cupcake 22",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto23,
    alt: "Cupcake 23",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto24,
    alt: "Cupcake 24",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto25,
    alt: "Cupcake 25",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto26,
    alt: "Cupcake 26",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto27,
    alt: "Cupcake 27",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto28,
    alt: "Cupcake 28",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto29,
    alt: "Cupcake 29",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto30,
    alt: "Cupcake 30",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto31,
    alt: "Cupcake 31",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto32,
    alt: "Cupcake 32",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto33,
    alt: "Cupcake 33",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto34,
    alt: "Cupcake 34",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto35,
    alt: "Cupcake 35",
    tags: ["Cupcakes"],
  },
  {
    src: CupcakePhoto36,
    alt: "Cupcake 36",
    tags: ["Cupcakes"],
  },

  {
    src: CakePhoto1,
    alt: "Cake 1",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto2,
    alt: "Cake 2",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto3,
    alt: "Cake 3",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto4,
    alt: "Cake 4",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto5,
    alt: "Cake 5",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto6,
    alt: "Cake 6",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto7,
    alt: "Cake 7",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto8,
    alt: "Cake 8",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto9,
    alt: "Cake 9",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto10,
    alt: "Cake 10",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto11,
    alt: "Cake 11",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto12,
    alt: "Cake 12",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto13,
    alt: "Cake 13",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto14,
    alt: "Cake 14",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto15,
    alt: "Cake 15",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto16,
    alt: "Cake 16",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto17,
    alt: "Cake 17",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto18,
    alt: "Cake 18",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto19,
    alt: "Cake 19",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto20,
    alt: "Cake 20",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto21,
    alt: "Cake 21",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto22,
    alt: "Cake 22",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto23,
    alt: "Cake 23",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto24,
    alt: "Cake 24",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto25,
    alt: "Cake 25",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto26,
    alt: "Cake 26",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto27,
    alt: "Cake 27",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto28,
    alt: "Cake 28",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto29,
    alt: "Cake 29",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto30,
    alt: "Cake 30",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto31,
    alt: "Cake 31",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto32,
    alt: "Cake 32",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto33,
    alt: "Cake 33",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto34,
    alt: "Cake 34",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto35,
    alt: "Cake 35",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto36,
    alt: "Cake 36",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto37,
    alt: "Cake 37",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto38,
    alt: "Cake 38",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto39,
    alt: "Cake 39",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto40,
    alt: "Cake 40",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto41,
    alt: "Cake 41",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto42,
    alt: "Cake 42",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto43,
    alt: "Cake 43",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto44,
    alt: "Cake 44",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto45,
    alt: "Cake 45",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto46,
    alt: "Cake 46",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto47,
    alt: "Cake 47",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto48,
    alt: "Cake 48",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto49,
    alt: "Cake 49",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto50,
    alt: "Cake 50",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto51,
    alt: "Cake 51",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto52,
    alt: "Cake 52",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto53,
    alt: "Cake 53",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto54,
    alt: "Cake 54",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto55,
    alt: "Cake 55",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto56,
    alt: "Cake 56",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto57,
    alt: "Cake 57",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto58,
    alt: "Cake 58",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto59,
    alt: "Cake 59",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto60,
    alt: "Cake 60",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto61,
    alt: "Cake 61",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto62,
    alt: "Cake 62",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto63,
    alt: "Cake 63",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto64,
    alt: "Cake 64",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto65,
    alt: "Cake 65",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto66,
    alt: "Cake 66",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto67,
    alt: "Cake 67",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto68,
    alt: "Cake 68",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto69,
    alt: "Cake 69",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto70,
    alt: "Cake 70",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto71,
    alt: "Cake 71",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto72,
    alt: "Cake 72",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto73,
    alt: "Cake 73",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto74,
    alt: "Cake 74",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto75,
    alt: "Cake 75",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto76,
    alt: "Cake 76",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto77,
    alt: "Cake 77",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto78,
    alt: "Cake 78",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto79,
    alt: "Cake 79",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto80,
    alt: "Cake 80",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto81,
    alt: "Cake 81",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto82,
    alt: "Cake 82",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto83,
    alt: "Cake 83",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto84,
    alt: "Cake 84",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto85,
    alt: "Cake 85",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto86,
    alt: "Cake 86",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto87,
    alt: "Cake 87",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto88,
    alt: "Cake 88",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto89,
    alt: "Cake 89",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto90,
    alt: "Cake 90",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto91,
    alt: "Cake 91",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto92,
    alt: "Cake 92",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto93,
    alt: "Cake 93",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto94,
    alt: "Cake 94",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto95,
    alt: "Cake 95",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto96,
    alt: "Cake 96",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto97,
    alt: "Cake 97",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto98,
    alt: "Cake 98",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto99,
    alt: "Cake 99",
    tags: ["Cakes"],
  },
  {
    src: CakePhoto100,
    alt: "Cake 100",
    tags: ["Cakes"],
  },
];

export default galleryPhotosData;
