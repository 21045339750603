import React from 'react';
import '../styles/AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1>About Sweetplus Cakehouse</h1>
      <p>
        Welcome to Sweetplus Cakehouse, where every cake is a work of art and every bite is a celebration! We are a custom cake bakery located in the heart of Prosper, Texas, dedicated to making your sweetest dreams come true.
      </p>
      
      <h2>Our Passion</h2>
      <p>
        At Sweetplus Cakehouse, baking is not just our job—it’s our passion. We believe that every cake should be as unique as the occasion it celebrates. That’s why we take the time to get to know you, your style, and your vision, so we can create a cake that is not only beautiful but also a reflection of your personality.
      </p>
      
      <h2>Our Process</h2>
      <p>
        From consultation to creation, we are with you every step of the way. We use only the finest ingredients, ensuring that every cake tastes as good as it looks. Whether you’re looking for an exciting celebration cake, a fun birthday cake, or something completely custom, we are here to bring your ideas to life.
      </p>
      
      <h2>Our Commitment</h2>
      <p>
        Quality and attention to detail are at the heart of everything we do. We take great pride in our work and are committed to providing you with a cake that is both delicious and memorable. No matter the size or complexity of the order, we approach every project with the same level of dedication and care.
      </p>

      <h2>Located in Prosper, Texas</h2>
      <p>
        We are proud to be part of the Prosper community! We love being a part of your special moments and are honored to have built lasting relationships with our clients in the area. Whether you’re local or planning an event in Prosper, we’re here to help make your occasion extra special.
      </p>

      <h2>Contact Us</h2>
      <p>
        Ready to make your cake dreams a reality? Contact us today to schedule a consultation or to place your order. We look forward to working with you!
      </p>

      <p></p>
    </div>
  );
}

export default AboutUs;
