// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-email-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }

  .confirmheader{

    background-color: #f9f9f9;
  }
  
  .confirm-email-content {
    max-width: 500px;
    padding: 40px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .confirm-email-content h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .confirm-email-content .login-btn {
    padding: 10px 20px;
    background-color: #d0021b;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .confirm-email-content .login-btn:hover {
    background-color: #b00118;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/ConfirmEmail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;;IAEE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,yCAAyC;IACzC,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".confirm-email-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f9f9f9;\n  }\n\n  .confirmheader{\n\n    background-color: #f9f9f9;\n  }\n  \n  .confirm-email-content {\n    max-width: 500px;\n    padding: 40px;\n    border-radius: 8px;\n    background-color: #ffffff;\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n  \n  .confirm-email-content h1 {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .confirm-email-content .login-btn {\n    padding: 10px 20px;\n    background-color: #d0021b;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    font-size: 16px;\n    cursor: pointer;\n    margin-top: 20px;\n  }\n  \n  .confirm-email-content .login-btn:hover {\n    background-color: #b00118;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
