// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PasswordRules.css */
.password-rules {
    text-align: left;
    margin-top: -20px;
    padding: 10px;
    border-radius: 5px;
    color: #5e5e5e;
    font-size: 14px;
    position: relative;
  }
  
  .password-rules ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .password-rules li {
    margin-top: 5px;
  }
  
  .password-rules li.valid {
    color: green;
  }
  
  .password-rules li.valid:before {
    margin-right: 5px;
    color: green;
  }
  
  .password-rules li:before {
    margin-right: 5px;
    color: red;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/PasswordRules.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,UAAU;EACZ","sourcesContent":["/* PasswordRules.css */\n.password-rules {\n    text-align: left;\n    margin-top: -20px;\n    padding: 10px;\n    border-radius: 5px;\n    color: #5e5e5e;\n    font-size: 14px;\n    position: relative;\n  }\n  \n  .password-rules ul {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n  }\n  \n  .password-rules li {\n    margin-top: 5px;\n  }\n  \n  .password-rules li.valid {\n    color: green;\n  }\n  \n  .password-rules li.valid:before {\n    margin-right: 5px;\n    color: green;\n  }\n  \n  .password-rules li:before {\n    margin-right: 5px;\n    color: red;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
