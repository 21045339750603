import React from 'react';
import '../styles/FAQ.css'; // Assuming the CSS file is in the styles folder

const FAQ = () => {
  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>

      <div className="faq-section">
        <h2>How far in advance should I place my order?</h2>
        <p>We recommend placing your order at least 2-3 weeks in advance, especially for custom cakes, to ensure availability.</p>
      </div>

      <div className="faq-section">
        <h2>Can I make changes to my order after it's placed?</h2>
        <p>Yes, you can make changes up to 72 hours before your scheduled pick-up or delivery. After that, changes may not be possible.</p>
      </div>

      <div className="faq-section">
        <h2>How should I store my cake?</h2>
        <p>We recommend storing your cake in a cool, dry place away from direct sunlight. Cakes with perishable fillings should be refrigerated.</p>
      </div>

      <div className="faq-section">
        <h2>Do you deliver?</h2>
        <p>Yes, we offer delivery services within a certain radius for an additional fee. Please contact us for more details.</p>
      </div>

      <div className="faq-section">
        <h2>What payment methods do you accept?</h2>
        <p>We accept all major credit cards, PayPal, and cash for local pickups.</p>
      </div>

      <div className="faq-section">
        <h2>What is your cancellation policy?</h2>
        <p>Cancellations made at least 7 days before the event will receive a full refund. Cancellations made within 7 days of the event will incur a 50% fee.</p>
      </div>

      <div className="faq-section">
        <h2>How much do custom cakes cost?</h2>
        <p>Pricing for custom cakes varies depending on size, design complexity, and flavors. Contact us for a detailed quote based on your specific needs.</p>
      </div>

      <div className="faq-section">
        <h2>What if I have a specific design in mind?</h2>
        <p>If you have a specific design in mind, feel free to share reference images or describe your vision to us. We'll do our best to bring your idea to life!</p>
      </div>

      <div className="faq-section">
        <h2>Can I pick up my order instead of having it delivered?</h2>
        <p>Yes, you can choose to pick up your order. Please specify your preference when placing your order.</p>
      </div>

      <div className="faq-section">
        <h2>What happens if my cake gets damaged during transport?</h2>
        <p>If you choose to pick up the cake yourself, we recommend driving carefully and placing the cake on a flat surface. We are not responsible for any damages after the cake leaves our premises. If we deliver, we ensure the cake arrives in perfect condition.</p>
      </div>

    </div>
  );
};

export default FAQ;
