import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useAdminAuth } from '../AdminAuthContext'; // Import the admin auth context
import '../styles/Navbar.css';

const Navbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const { isAdminAuthenticated, logoutAdmin } = useAdminAuth(); // Use the admin auth context
  const [menuOpen, setMenuOpen] = useState(false);
  const [accountHovered, setAccountHovered] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-brand">
          <img src="https://sweetpluscake.com/api/uploads/LogoNavbar.jpg" alt="Sweetplus Cakehouse" className="navbar-logo" style={{ height: '40px', width: 'auto' }}/>
        </Link>
        <div className="navbar-links">
          <Link to="/" className="navbar-link">Home</Link>
          <Link to="/gallery" className="navbar-link">Gallery</Link>
          <div
            className="navbar-link"
            onMouseEnter={() => setAccountHovered(true)}
            onMouseLeave={() => setAccountHovered(false)}
          >
            Account
            {accountHovered && (
              <div className="account-dropdown">
                <Link to="/account" className="dropdown-link">Account</Link>
                <Link to="/orders" className="dropdown-link">My Orders</Link>
                {isAuthenticated && (
                  <div onClick={logout} className="dropdown-link">Logout</div>
                )}
              </div>
            )}
          </div>
          <Link to="/order" className="navbar-link">Order</Link>
          {isAdminAuthenticated && (
            <Link to="/admin/dashboard" className="navbar-link">Admin Dashboard</Link>
          )}
        </div>
        <div className="navbar-hamburger" onClick={toggleMenu}>
          &#9776;
        </div>
      </div>
      {menuOpen && (
        <div className="navbar-overlay">
          <button className="close-button" onClick={toggleMenu}>&#x2715;</button>
          <div className="navbar-overlay-content">
            <Link to="/" className="navbar-link" onClick={toggleMenu}>Home</Link>
            <Link to="/gallery" className="navbar-link" onClick={toggleMenu}>Gallery</Link>
            <Link to="/order" className="navbar-link" onClick={toggleMenu}>Order</Link>
            <Link to="/account" className="navbar-link" onClick={toggleMenu}>Account</Link>
            {isAuthenticated && (
              <Link to="/orders" className="navbar-link" onClick={toggleMenu}>Your Orders</Link>
            )}
            {isAdminAuthenticated && (
              <Link to="/admin/dashboard" className="navbar-link" onClick={toggleMenu}>Admin Dashboard</Link>
            )}
            {isAuthenticated && (
              <Link to="/" onClick={() => { logout(); toggleMenu(); }} className="navbar-link">Logout</Link>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
