// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verify-email-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .verify-email-content {
    width: 100%;
    max-width: 500px;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .verifyheader{
    background-color: white;
  }
  
  .checkmark {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .verify-email-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .verify-email-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .resend-link, .login-link {
    color: #28a745;
    cursor: pointer;
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/VerifyEmail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,kBAAkB;EACpB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,0BAA0B;EAC5B","sourcesContent":[".verify-email-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n  }\n  \n  .verify-email-content {\n    width: 100%;\n    max-width: 500px;\n    background-color: #ffffff;\n    padding: 40px;\n    border-radius: 8px;\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n\n  .verifyheader{\n    background-color: white;\n  }\n  \n  .checkmark {\n    width: 50px;\n    height: 50px;\n    margin-bottom: 20px;\n  }\n  \n  .verify-email-content h2 {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .verify-email-content p {\n    font-size: 16px;\n    margin-bottom: 20px;\n  }\n  \n  .resend-link, .login-link {\n    color: #28a745;\n    cursor: pointer;\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
