// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    background-color: #f9f9f9;
  }
  
  .reset-password-content {
    max-width: 500px;
    padding: 40px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .reset-password-content h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .reset-password-btn {
    padding: 10px 20px;
    background-color: #d0021b;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .reset-password-btn:hover {
    background-color: #b00118;
  }
  
  .message {
    color: red;
    margin-top: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/ResetPassword.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,yCAAyC;IACzC,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB","sourcesContent":[".reset-password-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 50vh;\n    background-color: #f9f9f9;\n  }\n  \n  .reset-password-content {\n    max-width: 500px;\n    padding: 40px;\n    border-radius: 8px;\n    background-color: #ffffff;\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n  \n  .reset-password-content h1 {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .input-field {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 20px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 16px;\n  }\n  \n  .reset-password-btn {\n    padding: 10px 20px;\n    background-color: #d0021b;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    font-size: 16px;\n    cursor: pointer;\n  }\n  \n  .reset-password-btn:hover {\n    background-color: #b00118;\n  }\n  \n  .message {\n    color: red;\n    margin-top: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
