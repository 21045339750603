// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/OurPolicy.css */

.our-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .our-policy-container h1, 
  .our-policy-container h2 {
    color: #333;
  }
  
  .our-policy-container p {
    line-height: 1.6;
    color: #666;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/OurPolicy.css"],"names":[],"mappings":"AAAA,6BAA6B;;AAE7B;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;EAChB;;EAEA;;IAEE,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,WAAW;EACb","sourcesContent":["/* src/styles/OurPolicy.css */\n\n.our-policy-container {\n    padding: 20px;\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  .our-policy-container h1, \n  .our-policy-container h2 {\n    color: #333;\n  }\n  \n  .our-policy-container p {\n    line-height: 1.6;\n    color: #666;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
