// src/pages/OurPolicy.js

import React from 'react';
import '../styles/OurPolicy.css'; // You can create and style this CSS file as needed

const OurPolicy = () => {
  return (
    <div className="our-policy-container">
      <h1>Our Policy</h1>
      <p>
        Welcome to Sweetplus Cakehouse. We are committed to providing our customers with the best quality products and services. 
        Please read through our policy carefully.
      </p>
      <h2>Returns and Refunds</h2>
      <p>
        Due to the perishable nature of our products, we do not accept returns. However, if there is an issue with your order, 
        please contact us within 24 hours of receiving your order. We will do our best to resolve the issue.
      </p>
      <h2>Delivery Policy</h2>
      <p>
        We offer delivery within a certain radius of our location. Delivery fees may apply depending on the distance. 
        Please ensure someone is available to receive the order at the delivery address.
      </p>
      <h2>Privacy Policy</h2>
      <p>
        We respect your privacy and are committed to protecting your personal information. We will not share your information 
        with third parties without your consent.
      </p>
      <h2>Terms and Conditions</h2>
      <p>
        By placing an order with Sweetplus Cakehouse, you agree to our terms and conditions. Please review these terms 
        carefully before placing your order.
      </p>
    </div>
  );
}

export default OurPolicy;
