// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/groovepaper.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/App.css */

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Courier New', Courier, monospace;
  }
  
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .stitching-border {
    border-bottom: 2px dashed #aaa;
  }
  
  .content-background {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) repeat; /* Path to your texture image */
    flex: 1 1; /* Makes sure this div takes up the remaining space */
    display: flex;
    flex-direction: column;
    padding: 20px; /* Adjust padding as needed */
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,YAAY;IACZ,SAAS;IACT,UAAU;IACV,8CAA8C;EAChD;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,0DAAmD,EAAE,+BAA+B;IACpF,SAAO,EAAE,qDAAqD;IAC9D,aAAa;IACb,sBAAsB;IACtB,aAAa,EAAE,6BAA6B;EAC9C","sourcesContent":["/* src/styles/App.css */\n\nhtml, body {\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    font-family: 'Courier New', Courier, monospace;\n  }\n  \n  #root {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .app-container {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n  }\n  \n  .stitching-border {\n    border-bottom: 2px dashed #aaa;\n  }\n  \n  .content-background {\n    background: url('../assets/groovepaper.png') repeat; /* Path to your texture image */\n    flex: 1; /* Makes sure this div takes up the remaining space */\n    display: flex;\n    flex-direction: column;\n    padding: 20px; /* Adjust padding as needed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
