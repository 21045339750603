// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Courier New', Courier, monospace; /* Adjust to match your website's font */
    color: #000000;
  }
  
  .about-us-container h1, .about-us-container h2 {
    color: #555;
    margin-bottom: 20px;
    font-family: inherit; /* Inherit the font from the container */
  }
  
  .about-us-container p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
    font-family: inherit; /* Inherit the font from the container */
  }
  
  .about-us-container h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .about-us-container h2 {
    font-size: 1.5rem;
    margin-top: 30px;
  }
  
  .about-us-container p:last-child {
    text-align: center;
    font-size: 0.875rem;
    color: #777;
    margin-top: 40px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/AboutUs.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,8CAA8C,EAAE,wCAAwC;IACxF,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,oBAAoB,EAAE,wCAAwC;EAChE;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB,EAAE,wCAAwC;EAChE;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;EAClB","sourcesContent":[".about-us-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    font-family: 'Courier New', Courier, monospace; /* Adjust to match your website's font */\n    color: #000000;\n  }\n  \n  .about-us-container h1, .about-us-container h2 {\n    color: #555;\n    margin-bottom: 20px;\n    font-family: inherit; /* Inherit the font from the container */\n  }\n  \n  .about-us-container p {\n    font-size: 1rem;\n    line-height: 1.6;\n    margin-bottom: 20px;\n    font-family: inherit; /* Inherit the font from the container */\n  }\n  \n  .about-us-container h1 {\n    text-align: center;\n    margin-bottom: 30px;\n  }\n  \n  .about-us-container h2 {\n    font-size: 1.5rem;\n    margin-top: 30px;\n  }\n  \n  .about-us-container p:last-child {\n    text-align: center;\n    font-size: 0.875rem;\n    color: #777;\n    margin-top: 40px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
