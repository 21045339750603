// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }
  
  .order-success-content {
    width: 100%;
    max-width: 500px;
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .checkmark {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  .order-success-content h2 {
    font-size: 24px;
    background-color: white;
    margin-bottom: 20px;
  }
  
  .order-success-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .view-order-button {
    width: 100%;
    padding: 10px;
    background-color: #d0021b;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .view-order-button:hover {
    background-color: #a00116;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/OrderSuccess.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".order-success-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 60vh;\n  }\n  \n  .order-success-content {\n    width: 100%;\n    max-width: 500px;\n    background-color: white;\n    padding: 40px;\n    border-radius: 8px;\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n  \n  .checkmark {\n    width: 100px;\n    height: 100px;\n    margin-bottom: 20px;\n  }\n  \n  .order-success-content h2 {\n    font-size: 24px;\n    background-color: white;\n    margin-bottom: 20px;\n  }\n  \n  .order-success-content p {\n    font-size: 16px;\n    margin-bottom: 20px;\n  }\n  \n  .view-order-button {\n    width: 100%;\n    padding: 10px;\n    background-color: #d0021b;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    font-size: 16px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .view-order-button:hover {\n    background-color: #a00116;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
