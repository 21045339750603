import React from "react";
import "../styles/PasswordRules.css";

const PasswordRules = ({ password }) => {
  const minLength = password.length >= 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return (
    <div className="password-rules">
      <ul>
        <li className={minLength ? "valid" : ""}>
          {minLength ? "✔" : "✖"} At least 8 characters
        </li>
        <li className={hasUppercase ? "valid" : ""}>
          {hasUppercase ? "✔" : "✖"} At least one capital letter
        </li>
        <li className={hasSpecialChar ? "valid" : ""}>
          {hasSpecialChar ? "✔" : "✖"} At least one number or symbol
        </li>
      </ul>
    </div>
  );
};

export default PasswordRules;
