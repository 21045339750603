import React from 'react';
import '../styles/Footer.css'; // Assume the CSS is saved here

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3>Contact Us</h3>
          <ul>
            <li><a href="mailto:sweetpluscake.com">Email Us</a></li>
            <li><a target="_blank" href="https://www.instagram.com/sweetplus_cakehouse/?g=5">Instagram</a></li>
            <li><a target="_blank" href="https://www.facebook.com/sweetplus.cakehouse">Facebook</a></li>
            
          </ul>
        </div>
        <div className="footer-column">
          <h3>Customers</h3>
          <ul>
            <li><a href="/our-policy">Our Policy</a></li>
            <li><a href="/faq">FAQ</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Company</h3>
          <ul>
            <li><a href="/about-us">About Us</a></li>
            <li><a href="#">Privacy Policy</a></li>
            <li><a href="#">Terms</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Sweetplus Cakehouse</p>
      </div>
    </footer>
  );
}

export default Footer;
