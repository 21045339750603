import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import "../styles/Account.css";

const Account = () => {
  const { isAuthenticated } = useAuth();
  const [user, setUser] = useState({ firstName: "", lastName: "", email: "" });
  const [newName, setNewName] = useState({ firstName: "", lastName: "" });
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // State to control the visibility of edit fields
  const [editingName, setEditingName] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingPassword, setEditingPassword] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("User not logged in");
          setLoading(false);
          return;
        }

        const userResponse = await axios.get("https://sweetpluscake.com/api/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUser({
          firstName: userResponse.data.first_name,
          lastName: userResponse.data.last_name,
          email: userResponse.data.email,
        });
        setNewName({
          firstName: userResponse.data.first_name,
          lastName: userResponse.data.last_name,
        });
        setNewEmail(userResponse.data.email);
        setLoading(false);
      } catch (err) {
        setError("Error fetching user data");
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated]);

  // Handle success message timeout
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 1500); // 3000 milliseconds = 3 seconds

      // Cleanup the timer if the component unmounts or if the message changes
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleNameChange = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put("https://sweetpluscake.com/api/user/name", {
        firstName: newName.firstName,
        lastName: newName.lastName,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, ...newName }); // Retain the current email and only update the name
      setEditingName(false);
      setSuccessMessage("Name updated successfully");
    } catch (err) {
      setError("Error updating name");
    }
  };

  const handleEmailChange = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put("https://sweetpluscake.com/api/user/email", {
        email: newEmail,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser({ ...user, email: newEmail }); // Update only the email and retain the current name
      setEditingEmail(false);
      setSuccessMessage("Email updated successfully");
    } catch (err) {
      setError("Error updating email");
    }
  };

  const handlePasswordChange = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put("https://sweetpluscake.com/api/user/password", {
        password: newPassword,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEditingPassword(false);
      setSuccessMessage("Password updated successfully");
    } catch (err) {
      setError("Error updating password");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="accountinfo-container">
      <h1>Account Settings</h1>

      {successMessage && <p className="success-message">{successMessage}</p>}

      <div className="account-item">
    <h3>Name</h3>
    {editingName ? (
        <div className="name-input-container">
            <input
                type="text"
                placeholder="First Name"
                value={newName.firstName}
                onChange={(e) => setNewName({ ...newName, firstName: e.target.value })}
            />
            <input
                type="text"
                placeholder="Last Name"
                value={newName.lastName}
                onChange={(e) => setNewName({ ...newName, lastName: e.target.value })}
            />
            <button onClick={handleNameChange}>Save</button>
            <button onClick={() => setEditingName(false)}>Cancel</button>
        </div>
    ) : (
        <>
            <p>{user.firstName} {user.lastName}</p>
            <button onClick={() => setEditingName(true)}>Edit</button>
        </>
    )}
</div>


      <div className="account-item">
        <h3>Email</h3>
        {editingEmail ? (
          <>
            <input
              type="email"
              placeholder="New Email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <button onClick={handleEmailChange}>Save</button>
            <button onClick={() => setEditingEmail(false)}>Cancel</button>
          </>
        ) : (
          <>
            <p>{user.email}</p>
            <button onClick={() => setEditingEmail(true)}>Edit</button>
          </>
        )}
      </div>

      <div className="account-item">
        <h3>Password</h3>
        {editingPassword ? (
          <>
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button onClick={handlePasswordChange}>Save</button>
            <button onClick={() => setEditingPassword(false)}>Cancel</button>
          </>
        ) : (
          <>
            <p>********</p>
            <button onClick={() => setEditingPassword(true)}>Edit</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Account;
