// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer styling */
.footer {
    background-color: #faf5f3;
    padding: 40px 20px;
    font-family: 'Courier New', Courier, monospace;
    color: #333;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-column {
    flex: 1 1;
    margin: 0 20px;
  }
  
  .footer-column h3 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .footer-column ul li a {
    text-decoration: none;
    color: #333;
  }
  
  .footer-column ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #666;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,yBAAyB;IACzB,kBAAkB;IAClB,8CAA8C;IAC9C,WAAW;EACb;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,SAAO;IACP,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,qBAAqB;IACrB,WAAW;EACb;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,WAAW;EACb","sourcesContent":["/* Footer styling */\n.footer {\n    background-color: #faf5f3;\n    padding: 40px 20px;\n    font-family: 'Courier New', Courier, monospace;\n    color: #333;\n  }\n  \n  .footer-container {\n    display: flex;\n    justify-content: space-between;\n    max-width: 1200px;\n    margin: 0 auto;\n  }\n  \n  .footer-column {\n    flex: 1;\n    margin: 0 20px;\n  }\n  \n  .footer-column h3 {\n    font-size: 14px;\n    font-weight: bold;\n    margin-bottom: 20px;\n    text-transform: uppercase;\n  }\n  \n  .footer-column ul {\n    list-style: none;\n    padding: 0;\n  }\n  \n  .footer-column ul li {\n    margin-bottom: 10px;\n    font-size: 14px;\n  }\n  \n  .footer-column ul li a {\n    text-decoration: none;\n    color: #333;\n  }\n  \n  .footer-column ul li a:hover {\n    text-decoration: underline;\n  }\n  \n  .footer-bottom {\n    text-align: center;\n    margin-top: 20px;\n    font-size: 12px;\n    color: #666;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
